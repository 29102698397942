import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/shared/Layout'
import SEO from 'components/shared/SEO'
import CareerListItem from 'components/about/CareerListItem'

const IndexPage = ({ location, data }) => {
  const careers = [
    {
      companyName: 'Healingpaper',
      companyLogoUrl: '/images/healingpaper-logo.png',
      position: 'Web Frontend Lead',
      period: '2020.05 - Now',
    },
    {
      companyName: 'Code States',
      companyLogoUrl: '/images/code-states-logo.png',
      position: '(Freelancer) Growth Lead',
      period: '2020.02 - 2020.05',
    },
    {
      companyName: 'TREVARI',
      companyLogoUrl: '/images/trevari-logo.png',
      position: 'Tech Lead',
      period: '2017.08 - 2020.03',
    },
    {
      companyName: 'Freelancer',
      companyLogoUrl: '/images/freelancer-logo.png',
      position: 'Android Developer & UI/UX Designer',
      period: '2016.08 - 2017.07',
    },
    {
      companyName: '선의의 경쟁',
      companyLogoUrl: '/images/sellphone-logo.png',
      position: 'Android Developer & UI/UX Designer',
      period: '2016.02 - 2016.07',
    },
    {
      companyName: 'Place5',
      companyLogoUrl: '/images/place5-logo.png',
      position: 'Android Developer & UI/UX Designer',
      period: '2015.01 - 2016.01',
    },
  ]

  return (
    <>
      <SEO />
      <Layout location={location}>
        <div className="wrapper">
          <header className="py-14">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">
              Hey, I'm Wonny 🧡
            </h1>

            <p className="text-gray-500 text-sm md:text-base">
              I'm a Software Developer with Data Skills. I have mainly worked as
              a Tech Lead at Startups 🚀 Now I'm working as Web Frontend Lead at{' '}
              <a href="http://www.healingpaper.com/" target="_blank">
                Healingpaper
              </a>{' '}
              that serves{' '}
              <a href="https://about.gangnamunni.com/" target="_blank">
                Gangnamunni
              </a>
              .
            </p>
          </header>

          <main className="py-4">
            <div>
              <h2 className="text-xl font-bold bg-primary bg-opacity-50 inline-block px-1 leading-5 mb-10">
                <span className="mr-2">👩🏻‍💻</span> I've worked at
              </h2>

              <ul className="divide-gray-200 divide-y border-gray-200 border-b border-t">
                {careers.map((career) => (
                  <CareerListItem key={career.companyName} career={career} />
                ))}
              </ul>
            </div>
          </main>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

export default IndexPage
